import React from "react"
import { Image } from "../blocks/image"
import styled from "styled-components"

export const Icon = styled(({ name, ...styleProps }) => {
    return <Image {...styleProps} />
    })`
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    `
